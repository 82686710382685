<template>
    <div>
        <b-row>
            <b-col cols="12" md="4">
                <ValidationProvider name="academic_year" rules="" v-slot="{valid, errors}">
                    <b-form-group :label="$t('session')">
                        <academic-years-selectbox
                            :validateError="errors[0]"
                            v-model="filter.academic_year"
                        >
                        </academic-years-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import StudentReportService from "@/services/StudentReportService"
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"
import qs from "qs";

export default {
    components: {
        AcademicYearsSelectbox
    },
    data() {
        return {
            filter: {}
        }
    },
    methods: {
        getReport(){
            const config = {
                params: {
                    filter: {
                        ...this.filter
                    },
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
           StudentReportService.table11YokReport(config)
                .then(res=>this._downloadFile(res,this.$t('table_11_yok_report')+'.xlsx'))
                .catch(err=>this.showErrors(err))
        }
    }
}
</script>
