<template>
    <div>
        <ValidationObserver ref="filterForm">
        <b-row>
            <b-col cols="12" md="6">
                <ValidationProvider name="semester" rules="required" v-slot="{ valid, errors }">
                    <b-form-group :label="$t('semester')">
                        <semesters-selectbox
                            v-model="filter.semester_id"
                            :validate-error="errors[0]"
                        ></semesters-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('scholarship_rate')">
                    <scholarship-rate-selectbox v-model="filter.scholarship_rate"></scholarship-rate-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <ValidationProvider name="faculty" rules="" v-slot="{ valid, errors }">
                    <b-form-group :label="$t('faculty')">
                        <faculty-selectbox
                            v-model="filter.faculty_code"
                            :validate-error="errors[0]"
                        ></faculty-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('program')">
                    <program-selectbox
                        v-model="filter.program_code"
                        :faculty_code="filter.faculty_code"
                        :department_code="filter.department_code"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('report_type')">
                    <multi-selectbox
                        :allow-empty="false" :multiple="false"
                        :options="responseTypeOptions" v-model="responseType"
                    ></multi-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-button variant="primary" @click="getReport">
                    {{ $t('get_report').toUpper() }}
                </b-button>
            </b-col>
        </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
import ScholarshipRateSelectbox from '@/components/interactive-fields/ScholarshipRateSelectbox'
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import StudentProgramService from '@/services/StudentProgramService'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import qs from 'qs'

export default {
    components: {
        SemestersSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        ScholarshipRateSelectbox,
        MultiSelectbox,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            filter: {},
            responseType: 'page',
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'}
            ]
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.filterForm.validate()

            if (isValid) {
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        response_type: this.responseType
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                if (this.responseType != 'page') {
                    config.responseType = 'arraybuffer'
                }
                StudentProgramService.getYokRegistrationStatusReport(config)
                    .then((response) => {
                        if(this.responseType == 'page') {
                            this._openFile(response)
                        }
                        else if(this.responseType == 'excel') {
                            this._downloadFile(response, 'yok_registration_status_report.xlsx')
                        }
                        else if(this.responseType == 'pdf') {
                            this._downloadFile(response, 'yok_registration_status_report.pdf')
                        }
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        }
    }
}
</script>
