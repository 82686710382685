<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('period')">
                            <semesters-selectbox v-model="filter.semester_id"
                                :validateError="errors[0]"></semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
        </ValidationObserver>
        <b-row>
            <b-col cols="6" sm="4" md="3" lg="2">
                <b-form-group class="mb-0">
                    <b-button variant="primary" :active="!loading" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import DoubleMajorService from "@/services/DoubleMajorService";
import qs from 'qs';

export default {
    name: "examOverlapReport",
    data() {
        return {
            form: {},
            filter: {},
            loading: false
        }
    },
    methods: {
        async getReport() {
            let isValid = await this.$refs.formValidate.validate()
            if (!isValid) return


            const config = {
                params: {
                    filter: {
                        ...this.filter
                    },
                    response_type: "excel"
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            this.loading = true
            DoubleMajorService.leaveWithConsentReport(config)
                .then(res => this._downloadFile(res, this.$t('major_major_dismissal_report') + '.xlsx'))
                .catch(err => this.showErrors(err))
                .finally(() => this.loading = false)
        },
        clear() {
            this.filter = {}
        }
    }
}
</script>


<style lang="scss" scoped>
.form-group {
    .btn {
        line-height: 22px
    }
}
</style>
