<template>
    <div>
        <b-row>
            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>



import StudentDocumentService from "../../../../../services/StudentDocumentService";

export default {
    methods: {
        getReport(){
           StudentDocumentService.educationInformationReporting()
                .then(res=>this._downloadFile(res,this.$t('education_information_reporting')+'.xlsx'))
                .catch(err=>this.showErrors(err))
        }
    }
}
</script>
