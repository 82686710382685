<template>
    <div>
        <ValidationObserver ref="form">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="filter.faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="semester_id" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                :validateError="errors[0]"
                                v-model="filter.semester_id">
                            </semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('report_type')">
                        <multi-selectbox :allow-empty="false" :multiple="false"
                                         :options="responseTypeOptions"
                                         v-model="responseType"></multi-selectbox>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                    <b-form-group class="mb-0">
                        <label class="d-none d-md-block">&nbsp;</label>
                        <b-button variant="primary" block @click="getReport">
                            {{ $t('get_report').toUpper() }}
                        </b-button>
                    </b-form-group>
                </b-col>

            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
import qs from 'qs'
import DoubleMajorService from "@/services/DoubleMajorService";
import StudentReportService from "@/services/StudentReportService";

export default {
    data() {
        return {
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'},
            ],
            responseType:'page',
            filter: {},
            reportName: this.$t('student_numbers_by_nationality_and_faculty')
        }
    },
    methods: {
        async  getReport() {
            let isValid = await  this.$refs.form.validate()
            if(isValid){
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                return StudentReportService.studentNumbersByNationalityAndFaculty(config)
                    .then(this.callback)
                    .catch(e=>this.showErrors(e))
            }
        },
        callback(response) {
            if (this.responseType == 'page') {
                this._openFile(response);
            }
            else if (this.responseType == 'excel') {
                this._downloadFile(response, this.reportName + '.xlsx');
            }
            else if (this.responseType == 'pdf') {
                this._downloadFile(response, this.reportName + '.pdf');
            }
        }
    }
}
</script>
