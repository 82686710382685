<template>
    <div>
        <ValidationObserver ref="storeForm">
        <b-row>

                <b-col cols="12" md="3">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('period')">
                            <semesters-selectbox
                                v-model="filter.semester_id"
                                :validateError="errors[0]" ></semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            <b-col cols="12" md="3">
                <ValidationProvider name="registration_semester_id" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('report_type')">
                        <multi-selectbox
                            :allow-empty="false" :multiple="false"
                            :options="responseTypeOptions" v-model="responseType"
                        ></multi-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>

            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>

        </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
import qs from 'qs'
import DoubleMajorService from "@/services/DoubleMajorService";

export default {
    data() {
        return {
            responseType: 'page',
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'}
            ],

            selected: null,
            filter: {
                registration_semester_id: null,

            }
        }
    },
    methods: {
      async  getReport() {
            let isValid = await  this.$refs.storeForm.validate()
            if(isValid){
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        response_type:this.responseType
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                if(this.responseType!='page'){
                    config.responseType='arraybuffer'
                }
                return DoubleMajorService.downloadLeaveWithConsentExcelReport(config)
                    .then((response) => {
                        if(this.responseType=='excel'){
                            this._downloadFile(response, this.$t('leave_with_consent_cap_excel') + '.xlsx')
                        }else if (this.responseType=='page'){
                            this._openFile(response)
                        }else if (this.responseType=='pdf'){
                            this._downloadFile(response,'leave_with_consent_cap.pdf')
                        }

                    })
            }

        }
    }
}
</script>
