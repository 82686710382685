<template>
    <div>
        <ValidationObserver ref="examOverlapControl">
            <b-row>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_date')" class="position-relative">
                            <select-date  v-model="form.start_date" :validation-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="endDate" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('end_date')" class="position-relative">
                            <select-date  v-model="form.end_date" :validation-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
            </b-row>
        </ValidationObserver>
        <b-row>
            <b-col cols="6" sm="4" md="3" lg="2">
                <b-form-group class="mb-0">
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
            <b-col cols="6" sm="4" md="3" lg="2">
                <b-form-group class="mb-0">
                    <b-button variant="danger" block @click="clear">
                        {{ $t('clear').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import qs from 'qs'

import ExamScheduleServiceService from "@/services/ExamScheduleService";
import VueCal from "vue-cal";

export default {
    name: "examOverlapReport",
    components: {VueCal},
    data() {
        return {
            form: {}
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.examOverlapControl.validate();
            if (!isValid) {
                return
            }
            let config = {
                params: {
                        start_date:this.form.start_date,
                        end_date:this.form.end_date
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            ExamScheduleServiceService.verticalTransition(config)
                .then(response => {
                    const fileName=this.$t('vertical_transition_and_vertical_transition_supplementary')
                    this._downloadFile(response, fileName);
                })
                .catch(err => this.showErrors(err))
        },
        clear() {
            this.filter = {}
        }
    }
}
</script>


<style lang="scss" scoped>
.form-group {
    .btn {
        line-height: 22px
    }
}
</style>
