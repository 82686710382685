<template>
    <div>
        <b-row>
            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import StudentReportService from "@/services/StudentReportService";

export default {
    methods: {
        getReport() {
            StudentReportService.dischargeReport()
                .then(res => {
                    const fileName =this.$t('discharge_report_for_those_who_have_not_enrolled_in_courses')
                    this._downloadFile(res,fileName)
                })
                .catch(err => this.showErrors(err))
        }
    }
}
</script>

