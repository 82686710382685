<template>
    <div>
        <ValidationObserver ref="form">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <multi-selectbox :allow-empty="false" :multiple="false"
                                             :validate-error="errors[0]" :options="semesterOptions"
                                             v-model="filter.semester"></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>

                    <b-form-group :label="$t('faculty')">
                        <faculty-selectbox v-model="filter.faculty_code"
                        />
                    </b-form-group>

                    <b-form-group :label="$t('department')">
                        <department-selectbox v-model="filter.department"
                                              :faculty_code="filter.faculty_code"></department-selectbox>
                    </b-form-group>

                    <b-form-group :label="$t('program')" class="mb-1">
                        <program-selectbox v-model="filter.program"
                                           :faculty_code="filter.faculty_code" />
                    </b-form-group>
                </b-col>


                <b-col cols="12">
                    <processing-button @click="getReport" label="get_report"
                                       :processing="processing"></processing-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Components
// Services
import StudentReportService from '@/services/StudentReportService'

// Other
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import ProcessingButton from "@/components/elements/ProcessingButton";
import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    components: {
        ProcessingButton,
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,
        CourseAutoComplete
    },
    data() {
        return {
            semesterOptions: [
                {value: '1', text: this.$t('fall')},
                {value: '2', text: this.$t('spring')},
            ],
            processing: false,
            filter: {
            },
            reportName: this.$t('studentslot_studentsshouldtakecourses')
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.processing = true;
                let config = {params: this.filter};

                StudentReportService.studentsShouldTakeCourses(config).then((response) => {
                    this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                }).catch(e => {
                    this.showErrors(e, this.$refs.form);
                }).finally(() => {
                    this.processing = false;
                });
            }
        }
    }
}
</script>
