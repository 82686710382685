<template>
    <div>
        <b-row>
            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import CourseRegistrationService from "@/services/CourseRegistration";

export default {
    methods: {
        getReport() {
            CourseRegistrationService.exportExcelOfConfirmatedStudents()
                .then(res => {
                    this.$toast.success(this.$t('api.' + res.data.message));
                    this.$router.push('/job/logs/detail/' + res.data.data.log_id)
                })
                .catch(err => this.showErrors(err))
        }
    }
}
</script>
