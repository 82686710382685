<template>
    <div>
        <ValidationObserver ref="form">
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('faculty')">
                    <faculty-selectbox v-model="filter.faculty_code" :multiple="true"/>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('start_date')">
                        <b-form-input type="date" v-model="filter.start_date"/>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="6">
                <ValidationProvider name="end_date" rules="required" v-slot="{valid, errors}">
                <b-form-group :label="$t('end_date')">
                    <b-form-input type="date" v-model="filter.end_date"/>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('report_type')">
                    <multi-selectbox :allow-empty="false" :multiple="false"
                                     :options="responseTypeOptions"
                                     v-model="responseType"></multi-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
    // Components
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'

    // Services
    import StudentReportService from '@/services/StudentReportService'

    // Other
    import qs from 'qs'

    export default {
        components: {
            FacultySelectbox,
        },
        data() {
            return {
                responseTypeOptions: [
                    {value: 'page', text: this.$t('page')},
                    {value: 'excel', text: this.$t('excel')},
                    {value: 'pdf', text: 'PDF'},
                ],
                selected: null,
                filter: {},
                responseType:'page',
                reportName: this.$t('student_status_changes')
            }
        },
        methods: {
            async getReport() {
                const isValid = await this.$refs.form.validate();
                if (!isValid) {
                    return
                }
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        response_type: this.responseType,
                        page: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                if(this.responseType!='page'){
                    config.responseType = 'arraybuffer';
                }

                return StudentReportService.studentStatusChanges(config)
                    .then(this.callback)
                    .catch(e=>this.showErrors(e))
            },
            callback(response) {
                if (this.responseType == 'page') {
                    this._openFile(response);
                }
                else if (this.responseType == 'excel') {
                    this._downloadFile(response, this.reportName + '.xlsx');
                }
                else if (this.responseType == 'pdf') {
                    this._downloadFile(response, this.reportName + '.pdf');
                }
            }
        }
    }
</script>
